import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useChats } from '../../contexts/ChatsContext';
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  ToggleButton,
} from 'react-bootstrap';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import { getModelIcon, splitIntoGroups } from '../../utils/helpers';

const ChatForm = () => {
  const [chatInput, setChatInput] = useState<string>('');
  const [settingId, setSettingId] = useState<string>('');
  const { loadingState, handleSendMessage, activeChatId } = useChats();
  const { chatSettings } = useChatSettings();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const chatSettingsInChunks = useMemo(() => {
    return splitIntoGroups(chatSettings, 4);
  }, [chatSettings]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [activeChatId]);

  const onEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onFormSubmit();
    }
  };

  const onFormSubmit = () => {
    if (!chatInput.length || loadingState) return;

    clearChatInput();
    sendMessageToApi();
  };

  const onSendButtonClick = () => {
    onFormSubmit();
  };

  const clearChatInput = () => {
    setChatInput('');
  };

  const sendMessageToApi = () => {
    if (!chatInput.length || loadingState) return;

    handleSendMessage(chatInput, settingId);
  };

  const unClick = (id: string | undefined) => {
    if (id === settingId) {
      setSettingId('');
    }
  };

  return (
    <Form>
      {activeChatId === 'newChat' && (
        <>
          <Form.Group className='d-none d-lg-block'>
            <ButtonToolbar style={{ marginBottom: '10px' }}>
              {chatSettingsInChunks.map(chunk => {
                return (
                  <ButtonGroup
                    key={chunk[0].id}
                    style={{ width: '100%', marginBottom: '5px' }}
                    onClick={e => unClick((e.target as HTMLInputElement).value)}
                  >
                    {chunk.map(({ id, title, model_name }) => (
                      <ToggleButton
                        id={id}
                        key={id}
                        type='radio'
                        variant='light'
                        name='radio'
                        value={id}
                        checked={settingId === id}
                        onChange={e => setSettingId(e.currentTarget.value)}
                      >
                        {getModelIcon(model_name)}
                        <span style={{ marginRight: '10px' }} />
                        {title}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                );
              })}
            </ButtonToolbar>
          </Form.Group>
          <Form.Group className='d-lg-none'>
            <Form.Select
              value={settingId}
              className='mb-2'
              onChange={e => setSettingId(e.currentTarget.value)}
            >
              <option value=''>Select a setting</option>
              {chatSettings.map(({ id, title }) => (
                <option key={id} value={id}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
      <Form.Group style={{ position: 'relative' }}>
        <Form.Control
          as='textarea'
          rows={1}
          placeholder='Type in message here...'
          style={{ height: '100px', overflowY: 'scroll' }}
          onKeyDown={onEnterPress}
          value={chatInput}
          onChange={event => setChatInput(event.target.value)}
          ref={textAreaRef}
        />
        <span
          onClick={onSendButtonClick}
          style={{ position: 'absolute', right: '2%', top: '10%' }}
          className='d-lg-none floating-button material-symbols-outlined'
        >
          arrow_upward
        </span>
      </Form.Group>
    </Form>
  );
};

export default ChatForm;
