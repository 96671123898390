import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  IChat,
  IChatMessage,
  IChatsContext,
  IncomingMessage,
} from '../types/apiPayloads';
import {
  deleteChat,
  getChats,
  getMessages,
  initializeEventSource,
  sendMessage,
} from '../utils/api';
import { useAuth } from './AuthContext';
import { inputToMessage } from '../utils/helpers';
import { useErrors } from './ErrorsContext';

const ChatsContext: React.Context<IChatsContext> = createContext<IChatsContext>(
  {} as IChatsContext,
);

function ChatProvider({ children }: { children: ReactNode }) {
  const [activeChatId, setActiveChatId] = useState<string>('newChat');
  const [chats, setChats] = useState<IChat[]>([]);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [activeChatMessages, setActiveChatMessages] = useState<
    IncomingMessage[]
  >([]);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [activeSettingsId, setActiveSettingsId] = useState<string>('');

  const { isLoggedIn } = useAuth();
  const { setApiError } = useErrors();

  const loggedIn = useCallback(() => {
    return isLoggedIn();
  }, [isLoggedIn]);

  const activeChatIdRef: React.MutableRefObject<string> = useRef(activeChatId);
  // Update the ref whenever activeChatId changes
  useEffect(() => {
    activeChatIdRef.current = activeChatId;
  }, [activeChatId]);

  // load list of chats on page load
  useEffect(() => {
    if (!loggedIn()) {
      return;
    }
    const fetchData = async () => {
      setLoadingState(true);
      try {
        const chatList: IChat[] = await getChats();
        setChats(chatList);
        setLoadingState(false);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setApiError({ message: error.message, name: 'API error' });
          setLoadingState(false);
        } else {
          console.log('Unknown error');
        }
      }
    };
    fetchData();
  }, [loggedIn]);

  // get older messages of a chat that was opened
  useEffect(() => {
    if (!activeChatId || activeChatId === 'newChat') return;

    async function getChatMessages(chatId: string): Promise<void> {
      setLoadingState(true);
      try {
        const newData: IncomingMessage[] = await getMessages(chatId);
        setActiveChatMessages(newData);
        setLoadingState(false);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setApiError({ message: error.message, name: 'API error' });
          setLoadingState(false);
        } else {
          console.log('Unknown error');
          setLoadingState(false);
        }
      }
    }

    getChatMessages(activeChatId);
  }, [activeChatId]);

  // set current settings id when active chat is changed
  useEffect(() => {
    if (activeChatId === 'newChat') {
      setActiveSettingsId('');
      return;
    }
    const activeChat = chats.find(chat => chat.id === activeChatId);
    if (activeChat) {
      setActiveSettingsId(activeChat.chat_settings_id ?? 'Other');
    }
  }, [activeChatId, chats]);

  // start a new chat
  function startNewChat() {
    setActiveChatMessages([]);
    setActiveChatId('newChat');
  }

  // change the active chat
  function handleActiveChatChange(chatId: string) {
    if (chatId === 'newChat') {
      startNewChat();
      return;
    }
    setActiveChatId(chatId);
  }

  // send a message to the active chat
  async function handleSendMessage(
    chatInput: string,
    chatSettingsId: string,
  ): Promise<void> {
    const chatId = activeChatId === 'newChat' ? '' : activeChatId;
    const message: IChatMessage = inputToMessage(
      chatInput,
      chatId,
      chatSettingsId,
    );
    setLoadingState(true);
    try {
      const savedMessage: IncomingMessage = await sendMessage(message);
      if (savedMessage) {
        handleActiveChatChange(savedMessage.chat_id);
        setMessageLoading(true);
      } else {
        setApiError({
          message: 'Message was not sent, try again later',
          name: 'API error',
        });
      }
      setLoadingState(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
        setLoadingState(false);
      } else {
        console.log('Unknown error');
      }
    }
  }

  // delete chat permanently
  async function handleDeleteChat(chatId: string): Promise<boolean> {
    setLoadingState(true);
    try {
      const success: boolean = await deleteChat(chatId);
      if (success) {
        setLoadingState(false);
        setChats(chats.filter(({ id }) => id !== chatId));
      }
      return success;
    } catch (error: unknown) {
      if (error instanceof Error) {
        setApiError({ message: error.message, name: 'API error' });
        setLoadingState(false);
        return false;
      } else {
        console.log('Unknown error');
        setLoadingState(false);
        return false;
      }
    }
  }

  // subscribe to the event source on page load
  useEffect(() => {
    if (!isLoggedIn()) return;

    const evtSource = initializeEventSource(
      (newMessage: IncomingMessage) => {
        setActiveChatMessages((prevMessages: IncomingMessage[]) => [
          ...prevMessages,
          newMessage,
        ]);
        setMessageLoading(false);
        setApiError({ message: '', name: '' });
      },
      event => {
        console.log('Error event:', event);
        setApiError({ message: 'error', name: 'Event error' });
      },
      chatId => {
        if (chatId === activeChatIdRef.current || chatId === 'newChat') {
          setMessageLoading(true);
        }
      },
      chat => {
        const existingChat: IChat | undefined = chats.find(
          ({ id }) => id === chat.id,
        );
        setChats((prevChats: IChat[]) => {
          let updatedChats: IChat[];
          if (existingChat) {
            updatedChats = prevChats.map(existing =>
              existing.id === chat.id ? { ...existing, ...chat } : existing,
            );
          } else {
            updatedChats = [...prevChats, chat];
          }
          return updatedChats;
        });
      },
    );

    return () => {
      evtSource.close();
    };
  }, []);

  // for threads menu display
  // group chats by chat_settings_id and sort them so new chats are at the top
  const chatsGroupedBySettingsId = useMemo(() => {
    const sortedChats = [...chats].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
    return sortedChats.reduce(
      (acc, chat) => {
        const chatSettingsId = chat.chat_settings_id ?? 'Other';
        if (!acc[chatSettingsId]) {
          acc[chatSettingsId] = [];
        }
        acc[chatSettingsId].push(chat);
        return acc;
      },
      {} as Record<string, IChat[]>,
    );
  }, [chats]);

  return (
    <ChatsContext.Provider
      value={{
        activeChatId,
        chats,
        loadingState,
        handleActiveChatChange,
        handleDeleteChat,
        activeChatMessages,
        messageLoading,
        handleSendMessage,
        chatsGroupedBySettingsId,
        activeSettingsId,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
}

function useChats(): IChatsContext {
  return useContext(ChatsContext);
}

export { ChatProvider, useChats };
