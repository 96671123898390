import React from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './NavBar.css';

const NavBar = () => {
  const { logOut } = useAuth();

  const logout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logOut();
  };

  return (
    <Navbar
      expand='lg'
      className='bg-body-tertiary'
      style={{ borderRadius: '4px' }}
    >
      <Container>
        <Navbar.Brand href='#home'>MultiGPT</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} to='/chat'>
              Chat
            </Nav.Link>
            <Nav.Link as={Link} to='/chat-settings'>
              Settings
            </Nav.Link>
          </Nav>
          <Dropdown align='end'>
            <Dropdown.Toggle className='custom-dropdown-toggle rounded-circle '>
              <span className='material-symbols-outlined'>person</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to='/change-password'>
                Change Password
              </Dropdown.Item>
              <Dropdown.Item href='#' onClick={event => logout(event)}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
